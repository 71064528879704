import React from "react"

// UI
import Layout from "~components/layout"
import SEO from "~components/seo"
import CustomerHero from "~content/customers/detail/Hero"
import CustomerIntro from "~content/customers/detail/Intro"
import CustomerVideo from "~content/customers/detail/Video"

// Media
import imgHeroNaluPay from "~images/customers/nalupay/nalupay-hero.jpg"

const CustomerNaluPay: React.FC = () => {
  return (
    <Layout>
      <SEO
        canonicalTag="https://canopyservicing.com/customers/nalupay"
        description="Canopy Servicing is an API-first platform that is enabling the next generation of credit and lending products."
        title="NaluPay Case Study | Canopy Servicing"
      />

      <CustomerHero
        title="How NaluPay Built a Commercial BNPL Product with Canopy"
        subtitle="NaluPay, a point of sale system financing system for merchants and clients, needed a dynamic loan servicing platform that could serve several use cases. Of utmost importance was ensuring flexibility and scalability were present in one package."
        productTags={["Ledger", "DataDirect", "LoanLab", "Webhooks"]}
        teamTags={["Operations", "Product", "Credit & Analytics", "Engineering", "Legal & Compliance"]}
        img={imgHeroNaluPay}
        imgAlt="Novo Case Study Hero Image"
      />

      <CustomerIntro
        title="Financing Possibilities, Reimagined"
        body={
          <>
            NaluPay, launched by BHG Financial in July 2022, provides a modern, merchant-centric solution. Their
            high-ticket POS financing solution was designed to operate on the latest technology architecture and a
            growing suite of insightful analytics.
            <br />
            <br />
            Today, the company provides merchants with the ability to offer customers lines of credit, a suite of
            promotional and non-promotional financing options, and instant credit decisions.
          </>
        }
      />

      <CustomerVideo
        title={<span className="text-yellow-300">Building an MVP Lending Product with a Leap of Faith</span>}
        subtitle={
          <span className="text-gray-300">
            Former EVP of NaluPay, Matt Lekawa, was in charge of building out the product that would empower merchants
            to better serve their customers. As he sought out a lending core that would support their plans to scale
            with flexibility, he realized legacy systems were lacking the same vision.
          </span>
        }
        videoID="j-GQ2jEM--k"
        color="dark"
      />

      <CustomerVideo
        title="Ensuring Consumer Protection"
        subtitle="When building a new financial product or business model, it’s critical to keep consumer safety, transparency, and compliance at the heart of your system. With access to capital readily available through BNPL programs, NaluPay’s Matt Lekawa and his team were fully aligned with best practices recommended by the CFPB in 2022, such as built-in dispute processing."
        videoID="vS7_bfNz9EU"
      />
    </Layout>
  )
}

export default CustomerNaluPay
